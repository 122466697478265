/*
 * @Author: your name
 * @Date: 2021-03-02 13:23:16
 * @LastEditTime: 2023-09-08 14:18:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\vant.js
 */
import Vue from 'vue'
import {
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  RadioGroup,
  Radio,
  Uploader,
  Button,
  List,
  Icon,
  Tab,
  Tabs,
  TreeSelect,
  Popup,
  Grid,
  Picker,
  GridItem,
  Row,
  Col,
  Cell,
  Dialog,
  CellGroup,
  Checkbox,
  DatetimePicker,
  Switch,
  AddressList,
  Area,
  Tag,
  Rate,
  Search,
  PullRefresh,
  SwipeCell,
  Sticky,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem
} from 'vant'
const vantComponents=[
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  Radio,
  RadioGroup,
  Uploader,
  Button,
  List,
  Icon,
  Tab,
  Tabs,
  TreeSelect,
  Picker,
  Popup,
  Grid,
  GridItem,
  Row,
  Col,
  Cell,
  CellGroup,
  Checkbox,
  Dialog,
  Picker,
  Popup,
  DatetimePicker,
  Switch,
  AddressList,
  Area,
  Tag,
  Rate,
  Search,
  PullRefresh,
  SwipeCell,
  Sticky,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem
]

// 全局注册vant组件
vantComponents.forEach((component) => {
  Vue.use(component)
})
